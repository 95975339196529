import * as React from "react";
import { graphql } from "gatsby";

import { StaticImage } from "gatsby-plugin-image";
import wrapWithPostTemplate from "../../src/templates/post";
import Video from "../../src/components/video";
import { InlineMath } from "react-katex";
import Header from "../../src/images/header/flower.inline.svg";

export const frontmatter = {
  title: "Pharm Drone",
  subtitle: "Locating flowers in drone images of the Alberta prairies.",
  meta: "Python • Posted May 18, 2020",
  author: "hello@golsteyn.com",
  category: "projects",
  date: "2020-05-18T00:00:00.000Z",
};

const Flower = () => (
  <>
    <p>
      My dad, Dr. Roy Golsteyn,{" "}
      <a href="http://scholar.ulethbridge.ca/natural-products-cancer-cell-golsteyn/bio">
        is a scientist at the University of Lethbridge
      </a>{" "}
      in the department of Biology. His latest research project focuses on
      finding cancer drugs from the flora of southern Alberta. Important to his
      research is a significant amount of fieldwork to locate plants and bring
      them to the lab for further analysis.
    </p>
    <p>
      The best time to collect plants is while they are blooming. However, it is
      difficult to locate flowers in the semi-desertic landscape of southern
      Alberta.
    </p>
    <figure className="full raise">
      <div style={{ maxWidth: 600, margin: "0 auto" }}>
        <StaticImage src="../image/flower/alberta_landscape.jpg" />
        <figcaption>
          Example of a prairie field near Lethbridge, Alberta.
        </figcaption>
      </div>
    </figure>
    <p>
      Over the 2019/2020 winter break, my dad presented me with an interesting
      challenge:{" "}
      <b>
        is it possible to locate flowers given a top-down image of a prairie
        field taken from a DJI Phantom Pro drone?
      </b>
    </p>
    <p>This project can be divided into three tasks:</p>
    <ol>
      <li>
        Using a consumer-grade drone to survey a site with the potential for
        prairie flowers.
      </li>
      <li>Analyzing drone imagery to detect the presence of flowers.</li>
      <li>Using the result of that analysis to aid the harvesting process.</li>
    </ol>
    <figure className="full raise">
      <Video
        src="https://player.vimeo.com/video/416210547?autoplay=1"
        image={<StaticImage src="../image/flower/flower.png" />}
        ratio={9 / 16}
      />
      <figcaption>
        An early example of the algorithm in action, flowers located by the
        algorithm are demarkated by a coloured square
      </figcaption>
    </figure>
    <p>
      In this article, I will focus on the image analysis portion of this
      project. I will outline our initial approach to solve this computer vision
      task and outline areas for improvements.
    </p>
    <h2>Implementation</h2>
    <p>
      The implementation was split into two parts: first, we needed to train a
      model that could differentiate flower pixels from non-flower pixels
      (grass, earth, rocks, etc.). Then, we could use this model and apply it
      onto new images captured by a drone.
    </p>
    <h3>Training the model</h3>
    <p>
      We had access to a large database of southern Alberta flowers of 110
      species, 4000 images taken over 7 years. This database contained multiple
      pictures of different plant species in different lighting conditions.
    </p>
    <figure className="full">
      <StaticImage src="../image/flower/model-creation.png" />
    </figure>
    <p>
      The approach we took was to identify several colour clusters from the
      dataset. We manually cropped small image patches from the flower portion
      of the images. We then reshaped all the patches as a list of pixel values
      and filtered the value component.
    </p>
    <p>
      Left with only the hue and saturation component of each pixel, we applied
      k-means, with <InlineMath>k=6</InlineMath>, to retrieve a list of 6 colour
      values and the standard deviation of each cluster associated with flower
      pixels. These colours and deviations were used when conducting color
      thresholding on new images.
    </p>
    <h3>Processing new images</h3>
    <figure className="full">
      <StaticImage src="../image/flower/process-images.png" />
    </figure>
    <p>
      To determine if flowers were present in a new image, we made the following
      assumptions:
    </p>
    <ol>
      <li>
        <b>Flowers stand in contrast with their surrounding environment:</b>{" "}
        Keeping only edges would remove possible false-positives. We applied a
        Canny edge detector on the image.
      </li>
      <li>
        <b>
          Flowers can be segregated from their surrounding environment based on
          their colour (see above):
        </b>{" "}
        Applying colour thresholding using the colours from the colour model we
        generated would leave us with a mask of where flowers could be present.
      </li>
      <li>
        <b>
          If a flower is present in an image, then more than one pixel will have
          a value that makes it a flower candidate:
        </b>{" "}
        A single-pixel could indicate noise. We only keep clusters of pixels in
        the mask. This is done with an erosion morphological operation.
      </li>
    </ol>
    <p>
      The result was a small algorithm in Python, using OpenCV, which contours
      potential flowers. These contours, alongside the picture's geolocation
      information, could be used to determine if a particular site is ideal for
      flower harvesting.
    </p>
    <figure className="full">
      <StaticImage src="../image/flower/flower-results.png" />
    </figure>
    <h2>Future work</h2>
    <p>
      This algorithm was tested on a set of 7 images. It confirmed our
      assumptions that colour could be a good attribute for detecting the
      presence of flowers. The next step is to verify if this approach
      generalizes to a large image dataset of drone prairie images.
    </p>
    <p>
      We plan to move away using k-means for the colour model in favour of a
      supervised classification algorithm.
    </p>
    <p>
      We are also planning to develop a mobile application to allow researchers
      access to the processed images while on site. This application should help
      researchers select the best site to harvest.
    </p>
    <p>
      In the meantime, feel free to check{" "}
      <a href="https://github.com/The-Natural-Product-Laboratory/pharm-drone/">
        the code on GitHub!
      </a>
    </p>
  </>
);

export const query = graphql`
  query ($id: String) {
    javascriptFrontmatter(id: { eq: $id }) {
      frontmatter {
        author {
          email
          firstName
          name
        }
        category {
          name
        }
        meta
        subtitle
        title
        date
      }
    }
  }
`;

export default wrapWithPostTemplate(
  Flower,
  <Header alt="" className="hero_image" style={{ maxHeight: 150 }} />
);
